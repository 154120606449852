import { ElMessageBox } from 'element-plus'

/**
 * 编辑功能插件
 * 
 */
export default {
	_load: function( page, app ) {
		
		//页面索引
		let pageIndex = app._count.vue
		//本插件this对象
		let _this = this
		//缓存page对象
		this[pageIndex] = page
		
		//列表Setting配置
		let defaultSetting = {
			formName: 'EditForm',
			imageUpload: false
		}
		for(let name in defaultSetting) {
			if(page.setting[name] === undefined) {
				page.setting[name] = defaultSetting[name]
			}
		}
		
		
		//加载上传
		if(page.setting.imageUpload) {
			//字符串格式 表单名称
			if(typeof(page.setting.imageUpload) == 'string') {
				page.setting.imageUpload = [page.setting.imageUpload];
			}
			//遍历所有图片名称 初始化图片列表
			for(let name in page.setting.imageUpload){
				page.setting[page.setting.imageUpload[name]] = []
			}
			
			//获取base64格式图片
			page._getImageBase64 = function (file) {
				return new Promise(function(resolve, reject) {
					let reader = new FileReader()
					let base64Result = ''
					reader.readAsDataURL(file)
					reader.onload = function() {
						base64Result = reader.result.toString()
					}
					reader.onerror = function(error) {
						reject(error)
					}
					reader.onloadend = function() {
						resolve(base64Result)
					}
				})
			}
			
			//图片上传
			page._imageUpload = function (file, fileList, imageName) {
				if(!this.setting[imageName]) this.setting[imageName] = {}
				
				//扩展名验证
				if(this.setting[imageName].type) {
					if(this.setting[imageName].type.indexOf(file.raw.type) != -1) {
						return this._msg('请上传指定的图片格式')
					}
				}else {
					if(file.raw.type != 'image/jpeg' && file.raw.type != 'image/png' && file.raw.type != 'image/gif' && file.raw.type != 'image/bmp') {
						return this._msg('请上传jpg/png/gif/bmp格式的图片')
					}
				}
				
				//文件大小验证
				let fileSizeKb = file.raw.size / 1024
				if(this.setting[imageName].size) {
					if(fileSizeKb > this.setting[imageName].size) {
						return this._msg('图片超出限制大小，请压缩后上传')
					}
				}else {
					if(fileSizeKb > 3072000){
						return this._msg('图片超出3M，请压缩后上传')
					}
				}
				
				//图片上传
				this._getImageBase64(file.raw).then(base64 => {
					let apiUrl = this.setting[imageName].action
					if(!apiUrl) apiUrl = '/Common/Image/upload'
					this._post(apiUrl, { name: imageName, image: base64}, (res)=>{
						let imagePath = res.data.path
						if(!this.form[imageName]) {
							this.form[imageName] = []
						}
						this.form[imageName].push(imagePath)
					})
				})
				
				return false
			}
			
			//上传上限提示
			page._imageUploadExceed = function (file) {
				if(file) this._msg('超出图片上传上限')
			}
			
			//图片预览
			page._imageUploadPreview = function (file) {
				ElMessageBox.alert(
					'<div class="-wrap"><img src="'+file.url+'" style="width:100%; height:auto" /></div>',
					'图片预览',
					{
						dangerouslyUseHTMLString: true,
						showConfirmButton: false,
						closeOnClickModal: true,
						closeOnPressEscape: true,
						customClass: '-image-preview'
					}
				)
			}
			
			//图片删除
			page._imageUploadRemove = function (file, fileList, imageName) {
				let imageDotIndex = file.url.indexOf('/img');
				if(imageDotIndex!=-1) {
					let findUrl = file.url.substr(imageDotIndex)
					let isBreak = false
					this.form[imageName].forEach((url,index)=>{
						if(isBreak) return
						if(url.indexOf(findUrl)!=-1){
							this.form[imageName].splice(index,1)
							this.setting[imageName].splice(index,1)
							isBreak = true
						}
					})
				}
				
			}
		}//End Upload Image
		
		//清空临时变量
		defaultSetting = undefined
		
		
		
		
		
		//编辑初始化
		if(page.setting.detailApi || page.editId){
			page.form.id = page.editId
			let apiUrl = page.setting.detailApi
			if(!apiUrl) {
				apiUrl = page.setting.moduleApi + 'detail'
			}
			page._post(apiUrl, { id: page.form.id}, (res)=>{
				if(page.detailCall) {
					let detailResult = page.detailCall(res)
					if(detailResult) return false
				}
				page.form = Object.assign(page.form, res.data)
				//选项
				if(res.other && res.other.options) {
					for(let name in res.other.options){
						page.options[name] = res.other.options[name]
					}
				}
				//遍历图片
				if(page.setting.imageUpload)
				for(let name in page.setting.imageUpload){
					let imageName = page.setting.imageUpload[name]
					//存在图片列表
					if(res.data[imageName].length)
					res.data[imageName].forEach((url,index)=>{
						if(!url) return
						page.setting[imageName].push({url: url.indexOf('http')===0?url:res.data.domain + url})
					})
				}
			})
		}
		//添加初始化
		if(page.setting.addDetailApi){
			let apiUrl = page.setting.addDetailApi
			if(apiUrl === true) {
				apiUrl = page.setting.moduleApi + 'addDetail'
			}
			page._post(apiUrl, {}, (res)=>{
				page.form = Object.assign(page.form, res.data)
				if(res.other && res.other.options) {
					for(let name in res.other.options){
						page.options[name] = res.other.options[name]
					}
				}
			})
		}
		
		
		//表单提交
		if(typeof(page._formSubmit) != 'function')
		page._formSubmit = function(){
			this.$refs[this.setting.formName].validate((valid) => {
				if (valid) {
					let apiUrl = this.setting.editApi
					if(!apiUrl) {
						apiUrl = this.setting.moduleApi
						if(this.form.id) {
							apiUrl += 'edit'
						}else {
							apiUrl += 'add'
						}
					}
					this._post(apiUrl, this.form, (res)=>{
						if(this.editCall) {
							this.editCall(res)
						}else {
							this._msg(this.form.id?'更新成功':'添加成功')
						}
						this.closeDrawer()
					})
				} else {
					return false
				}
			})
			return false
		}
		
		//表单重置
		if(typeof(page._formReset) != 'function')
		page._formReset = function(){
			this.$refs[this.setting.formName].resetFields()
		}
		
		
		//加载完毕
		page.setting.loaded = true
	}
}