
import { reactive } from 'vue'
import _list from '@/assets/js/list'
import Add from '@/views/system/role/add.vue'
import Edit from '@/views/system/role/edit.vue'

export default {
	components: { Add, Edit },
	setup(props, context) {
		return {
			options: reactive({}),
			setting: reactive({
				moduleName: '角色',
				moduleApi: '/System/Role/',
				moduleType: 'full',
			}),
			list: reactive({data: []}),
		}
	},
	mounted() {
		this._load(this, _list)
		this._listSearch()
	},
	methods: {
		
	}
}
