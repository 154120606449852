
import { reactive, ref, getCurrentInstance } from 'vue'
import type { ElTree } from 'element-plus'
import type Node from 'element-plus/es/components/tree/src/model/node'
import _edit  from '@/assets/js/edit'

interface Tree {
	id: number
	label: string
	children?: Tree[]
}

export default {
	components: {},
	props: [ 'editId' ],
	setup(props, context) {
		const treeRef = ref<InstanceType<typeof ElTree>>()
		
		const getCheckedKeys = () => {
			return treeRef.value!.getCheckedKeys(false)
		}
		const getCheckedNodes = () => {
			return treeRef.value!.getCheckedNodes(false, false)
		}
		const instance = getCurrentInstance()
		
		return {
			treeRef,
			getCheckedNodes,
			getCheckedKeys,
			instance,
			context,
			treeSelect: reactive([]),
			treeData: reactive([]),
			closeDrawer: ()=>{context.emit('onClose', 'edit')},
			view: reactive({}),
			form: reactive({
				permissionId: []
			}),
			options: reactive({}),
			setting: reactive({
				moduleApi: '/System/Role/',
				moduleName: '角色',
			}),
			rules: {
				name: [
					{ required: true, message: '请输入角色名称', trigger: 'blur' },
				],
				permissionId: [
					{ required: true, message: '请选择权限', trigger: 'blur' },
				],
			},
		}
	},
	
	mounted: function(){
		this._load(this, _edit)
	},
	methods: {
		
		//详情回调
		detailCall: function(res){
			this.treeData = res.data.menu
			this.treeSelect = res.data.permissionId
			delete res.data.menu
			this.form = Object.assign(this.form, res.data)
			this.instance.proxy.$forceUpdate()
		},
		
		//表单提交
		_formSubmit: function(){
			let permissionId = this.getCheckedKeys()
			if(!permissionId || (permissionId.length==0)) return this._msg('请选择权限项目')
			this.form.permissionId = permissionId
			
			this.$refs[this.setting.formName].validate((valid) => {
				if (valid) {
					this._post(this.setting.moduleApi + 'edit', this.form, (res)=>{
						this._msg('更新成功')
						this.closeDrawer()
					})
				} else return false
			})
			return false
		}
	}
}
